import { Grid, InputAdornment, Box, Modal, RadioGroup, FormControlLabel, Radio } from "@mui/material"
import { PricingRule, sortStageTypes, StageType } from "api/common"
import { RadioField } from "common/RadioField"
import { SumbitButtons } from "common/SubmitButtons"
import { NormalTextField, WhiteTextField } from "common/TextField"
import { RaRecord, useDataProvider, useNotify, useCreate, useRefresh, useRecordContext } from "ra-core"
import { Loading } from "ra-ui-materialui"
import React, { ChangeEvent, FormEvent, useEffect, useState } from "react"
import { PriceList } from "./PricingList"
import { ModalProps } from "./common"

import 'styles.scss'
import { CheckboxField } from "common/CheckboxField"
import RoundedPanel, { Alert } from 'common/RoundedPanel'
import { PricingType } from "api/company"

export interface StageTypeWithId {
  id: string
  stageType: StageType
}

type TrustMiningSelection = "TM1"|"TM2"
interface TrustMiningProps {
  selected?: TrustMiningSelection,
  setSelected: (e: TrustMiningSelection) => void
  disabled?: boolean
  label?: string
}
const TrustMiningSelectField = ({ selected, setSelected, disabled }: TrustMiningProps) => {
  const record = useRecordContext()
  const stage = record.stageType as StageType

  if(stage === "TRUST_MINING") {
    return <RadioGroup
      sx={{ justifyContent: 'right' }}
      row
      value={selected}
      onChange={(e: ChangeEvent<HTMLInputElement>) => { 
        setSelected((e.target as HTMLInputElement).value as TrustMiningSelection) 
      }}
    >
      <FormControlLabel value="TM1" control={<Radio size='small' disabled={disabled} />} label="1" />
      <FormControlLabel value="TM2" control={<Radio size='small' disabled={disabled} />} label="2" />
    </RadioGroup>
  }
  return null
}

type CreatePricingRuleModalProps = ModalProps<RaRecord|undefined> & {
  type: PricingType
}

export const CreatePricingRuleModal = ({ open, onClose, record, type  }: CreatePricingRuleModalProps) => {

  const [pricingRules, setPricingRules] = useState<StageTypeWithId[]>([])
  const [isLoading, setIsLoading] = useState<boolean>(true)
  const [wordsQuantity, setWordsQuantity] = useState<number>(100)
  const [newPrice, setNewPrice] = useState<number>(0)
  const [selected, setSelected] = useState<Set<string>>(new Set())
  const [trustMining, setTrustMining] = useState<TrustMiningSelection>("TM1")
  
  const dataProvider = useDataProvider()

  const notify = useNotify()
  const [create] = useCreate()
  const refresh = useRefresh()
  
  const companyId = record?.id
  
  const trustMiningId = pricingRules.find(({ stageType }) => stageType == "TRUST_MINING")?.id as string

  const getSelectedList = () => {
    const stages = pricingRules.filter(({ id }) => selected.has(id as string)).map((i) => i.stageType)
    
    if(trustMining=="TM2" && selected.has(trustMiningId)) {
      stages.push('TRUST_MINING')
    }
    return sortStageTypes(stages)
  }
  
  const createPricingRule = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault()
    
    create('pricingrules',
      { data: { price: newPrice, stages: getSelectedList(), companyId: companyId, type: type } },
      {
        onSuccess: () => {
          notify('Price rule was successfully created.', { type: 'success' })
          refresh()
          onClose()
        },
        onError: (error: any) => {
          refresh()
          notify(error?.message ?? 'Something went wrong.', { type: 'error' })
          console.log(JSON.stringify(error))
        }
      }
    )
  }
  
  useEffect(() => {
    dataProvider
      .getList("pricingrules/stage-types", {
        pagination: { page: 1, perPage: 1 },
        sort: { field: "id", order: "ASC" },
        filter: { companyId: companyId }
      })
      .then(({ data }) => {
        setPricingRules(data as StageTypeWithId[])
      })
      .catch(() => {
        console.warn('Something went wrong during fetching pricing rules.')
      })
      .finally(() => {
        setIsLoading(false) 
      })
  }, [open])

  const formatNumber = (num?: number, prec?: number) => {
    return num ? parseFloat(num.toFixed(prec||5)) : undefined
  }

  const formatPriceInput = (price: number) => {
    return formatNumber(wordsQuantity*price)
  }
  
  const CurrentPriceList = () => {
    return <PriceList isSingle={true} companyId={companyId}>
      <TrustMiningSelectField
        selected={selected?.has(trustMiningId) ? trustMining : undefined}
        disabled={!(selected?.has(trustMiningId))}
        setSelected={(e) => { setTrustMining(e) }}
      />
      <CheckboxField
        selected={selected}
        label="Select"
        size='small'
        setSelected={(e) => { setSelected(e) }}
      />
    </PriceList>
  }
  
  const addForm = <Grid container justifyContent="center" alignItems="center" sx={{ minHeight: "100%" }}>
    <form
      onSubmit={createPricingRule}
    >
      <Grid container spacing="14px">
        <Grid item xs={12}>
          <NormalTextField
            fullWidth
            id="pricePerWord"
            type="number"
            label="Price per word"
            onChange={(e) => { setNewPrice(parseFloat(e.target.value)) }}
            InputProps={{
              endAdornment: <InputAdornment position="start">€</InputAdornment>
            }}
            inputProps={{
              step: 0.0001,
              min: 0,
              max: 99
            }}
          />
        </Grid>
        <Grid item xs={12}>
          <NormalTextField
            fullWidth
            id="numOfWords"
            type="number"
            label="Number of words"
            onChange={(e) => { setWordsQuantity(parseInt(e.target.value)) }}
            value={wordsQuantity}
          />
        </Grid>
        <Grid item xs={12}/>
        <Grid item xs={12}>
          <NormalTextField
            fullWidth
            disabled
            value={formatPriceInput(newPrice)}
            label="Exfluency fee with new prices per word:"
            InputProps={{
              endAdornment: <InputAdornment position="start">€</InputAdornment>
            }}
          />
        </Grid>
      </Grid>
      <Box height={32} />
      <SumbitButtons
        onClose={onClose}
      />
    </form>
  </Grid>
  
  return <Modal
    open={open}
    onClose={onClose}
    disableAutoFocus={true}
  >
    <RoundedPanel modal columns width="1100px">
      <Box width="620px">
        {isLoading ? <Loading /> : <CurrentPriceList/>}
      </Box>
      <Box width="415px">
        {addForm}
      </Box>
    </RoundedPanel>
  </Modal>
}