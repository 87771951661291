import { TagDto } from 'api/tags'
import React from 'react'
import {
  Datagrid,
  EditButton,
  Filter,
  FunctionField,
  List,
  ListProps,
  TextInput,
  WrapperField
} from 'react-admin'
import { formatEnumForDisplay } from 'utils/helpers'
import { TagField } from './Tag'
import { TagEntityTypeSelector } from './TagEntityTypeSelector'

const TagFilter = (props: any) => (
  <Filter {...props}>
    <TagEntityTypeSelector source="entityType" alwaysOn />
    <TextInput label="Tag Name" source="name" alwaysOn />
  </Filter>
)

export const TagList: React.FC<ListProps> = (props) => (
  <List filters={<TagFilter />} {...props}>
    <Datagrid bulkActionButtons={false}>
      <FunctionField
        source="entityType"
        render={(record?: TagDto) => formatEnumForDisplay(record?.entityType ?? '')}
      />
      <WrapperField label="Tag">
        <TagField />
      </WrapperField>
      <EditButton />
    </Datagrid>
  </List>
)
