import { Modal, Box, TextField } from "@mui/material"
import { RaRecord, useNotify, useDataProvider, useRefresh } from "ra-core"
import React, { useState } from "react"
import { ModalProps } from "./common"

import RoundedPanel, { CrossCheckbox } from "common/RoundedPanel"
import { SumbitButtons } from "common/SubmitButtons"
import { PricingType, Tablet } from "api/company"
import { StageType, isHumanExecuted } from "api/common"

interface CreateTabletModalProps {
  type: PricingType
  tablet?: Tablet
}

export const CreateTabletModal = ({ open, onClose, record, type, tablet }: ModalProps<RaRecord> & CreateTabletModalProps) => {
  const { stages, companyId } = record

  const defaultTitle = "Translation Services"
  const defaultDescription = "Select to order translation with double quality assurance. Your text will be trust mined and enhanced by two qualified specialists."

  const [title, setTitle] = useState<string>(tablet?.title || defaultTitle)
  const [description, setDescription] = useState<string>(tablet?.description || defaultDescription)
  const [quoting, setQuoting] = useState<boolean | undefined>(tablet?.quoting)

  const notify = useNotify()
  const refresh = useRefresh()
  const dataProvider = useDataProvider()

  const notifyWarning: ((reason: any) => void | PromiseLike<void>) | null | undefined = (error) => {
    notify(error?.message ?? 'Something went wrong.', { type: 'error' })
  }

  const applyChanges = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault()
    if (tablet) {
      dataProvider.update(`tablets`, {
        id: tablet.id,
        data: { title: title, quoting: quoting },
        previousData: tablet
      })
        .then(() => {
          notify('Tablet was successfully updated.', { type: 'success' })
          refresh()
          onClose()
        })
        .catch(notifyWarning)
        .finally(refresh)
    } else {
      dataProvider
        .create(`tablets`, {
          data: {
            title: title,
            stages: (stages as StageType[]).map((s) => ({
              stage: s,
              executorSelection: isHumanExecuted(s) ? "auto" : null
            })),
            companyId: companyId,
            pricingType: type,
            quoting: quoting,
            multiLang: true,
            type: "OUTSOURCING",
            priorityGroup: 2,
            tabletOrder: 0,
            status: "ACTIVE"
          }
        })
        .then(() => {
          notify("Tablet was successfully created.", { type: "success" })
          refresh()
          onClose()
        })
        .catch(notifyWarning)
        .finally(refresh)

    }
  }

  const onDelete = (tabletId: string) => {

    dataProvider
      .delete('tablets', { id: tabletId })
      .then(() => {
        notify('Tablet successfully deleted.', { type: 'success' })
        onClose()
      })
      .catch(notifyWarning)
      .finally(refresh)
  }

  return <Modal
    open={open}
    onClose={onClose}
    disableAutoFocus={true}
  >
    <RoundedPanel modal width="400px">
      <h1>Create Tablet</h1>
      <form
        onSubmit={applyChanges}
      >
        <TextField
          sx={{ '& .MuiInputBase-input': {
            fontSize: '0.9rem'
          }, 
          marginTop: '20px' }}
          id="title"
          label="Title"
          defaultValue={title}
          fullWidth
          onChange={(e) => { setTitle(e.target.value) }}
        />
        <TextField
          sx={{ '& .MuiInputBase-input': {
            fontSize: '0.9rem'
          } }}
          id="description"
          label="Description"
          defaultValue={description}
          onChange={(e) => { setDescription(e.target.value) }}
          fullWidth
          minRows={4}
          multiline
        />
        <Box height={13} />
        <CrossCheckbox checked={quoting} onChange={(e) => setQuoting(e.target.checked)} />Quoting<br />
        <Box height={32} />
        <SumbitButtons onClose={onClose} onDelete={tablet && (() => onDelete(tablet.id))} onDeleteLabel="Delete tablet"/>
      </form>
    </RoundedPanel>
  </Modal>
}
