import React, { useEffect, useState } from 'react'
import { Autocomplete, Box, IconButton } from '@mui/material'
import { TagDto, getAllTags } from 'api/tags'
import { NormalTextField } from 'common/TextField'
import Tag from './Tag'
import CloseIcon from '@mui/icons-material/Close'

interface TagSelectorProps {
  entityType?: string
  value: TagDto[]
  onChange: (newValue: TagDto[]) => void
  placeholder?: string
  label?: string
  filterSelected?: boolean
  showDeleteIcon?: boolean
}

const TagForEntitySelector = ({ 
  entityType,
  value,
  onChange,
  placeholder = "Select tags",
  label = "Tags"
}: TagSelectorProps) => {
  const [availableTags, setAvailableTags] = useState<TagDto[]>([])
  const [isLoading, setIsLoading] = useState(false)

  useEffect(() => {
    const fetchTags = async () => {
      setIsLoading(true)
      try {
        const tags = await getAllTags(entityType, 50)
        setAvailableTags(tags)
      } catch (error) {
        console.error('Error fetching tags:', error)
      }
      setIsLoading(false)
    }
    fetchTags()
  }, [entityType])

  const options = availableTags.filter(tag => !value.some(s => s.id === tag.id))

  return (
    <Autocomplete
      multiple
      size="small"
      options={options}
      loading={isLoading}
      value={value}
      onChange={(_, newValue) => onChange(newValue)}
      getOptionLabel={(option) => option.name}
      isOptionEqualToValue={(option, value) => option.id === value.id}
      renderInput={(params) => (
        <NormalTextField
          {...params}
          label={label}
          placeholder={placeholder}
        />
      )}
      renderOption={(props, option) => (
        <Box component="li" {...props}>
          <Tag
            name={option.name}
            color={option.color}
          />
        </Box>
      )}
      renderTags={(tagValue, getTagProps) =>
        tagValue.map((option, index) => {
          const { onDelete, ...otherProps } = getTagProps({ index })
          return (
            <Box component="span" {...otherProps}>
              <Tag
                name={option.name}
                color={option.color}
                endIcon={
                  onDelete && (
                    <IconButton
                      size="small"
                      onClick={onDelete}
                      sx={{ ml: 0.5, p: 0.2 }}
                    >
                      <CloseIcon fontSize="small" sx={{ color: 'inherit' }} />
                    </IconButton>
                  )
                }
              />
            </Box>
          )
        })
      }
    />
  )
}

export default TagForEntitySelector 