import { Box, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from '@mui/material'
import { CustomDateField, CustomLinkField, GatedCommunitySearchFilter, LanguageFilter } from 'common'
import { languages } from 'common/dictonary'
import { stripCategoryAndIQVIAPrefix } from 'company/showTabs/PortalProperties'
import React from 'react'
import { DatagridConfigurable, Edit, FilterButton, FunctionField, Identifier, List, SaveButton, SelectArrayInput, SelectColumnsButton, SelectInput, SimpleForm, TextField, TextInput, Toolbar, TopToolbar, required, useEditContext, useListContext, useNotify, useRecordContext, useRedirect, useRefresh, useResourceContext, useStore } from 'react-admin'
import { requiredNotBlank, showTimeInTimezone } from 'subassets/SubAssetsList'
import { getOptionsSelectFromArray } from 'utils/helpers'
import { convertTaxonomyKeysToOptions, TaxonomyMultiSearchInput } from './TaxonomyMultiSearch'

const SINGLE_PAGE_LIMIT = 100
const DICT_LANG = 'dict.lang.'

const assetsFilters = [
  <LanguageFilter label="Source lang" source="sourceLang" alwaysOn />,
  <LanguageFilter label="Target lang" source="targetLang" alwaysOn />,
  <TextInput label="Text" source="text" alwaysOn size='medium'/>,
  <SelectInput source="searchType" validate={required()} size='medium' sx={{ marginBottom: '0px' }} choices={[
    { id: 'SOURCE_EXACT', name: 'Source text exact match' },
    { id: 'SOURCE_FULL', name: 'Source full text search' },
    { id: 'TARGET_EXACT', name: 'Target text exact match' },
    { id: 'TARGET_FULL', name: 'Target full text search' },
    { id: 'ANY_EXACT', name: 'Any text exact match' },
    { id: 'ANY_FULL', name: 'Any full text search' }
  ]} alwaysOn />,
  <GatedCommunitySearchFilter alwaysOn source='gatedCommunities' />,
  <TextInput label="Project Id" source="projectId" alwaysOn size='medium' resettable />,
  <SelectArrayInput source="translationLevels" alwaysOn options={{ sx: { height: '56px', width: '200px', margin: '0px' } }} choices={[
    { id: 'MT', name: 'MT' },
    { id: 'ENTERED', name: 'Entered' },
    { id: 'ENHANCED', name: 'Enhanced' },
    { id: 'HUMAN_PARITY', name: 'Human Parity' },
    { id: 'VERIFIED', name: 'Verified' },
    { id: 'SUPERVERIFIED', name: 'Super Verified' }
  ]} />,
  <SelectInput source="creationSource" size='medium' sx={{ marginBottom: '0px' }} choices={[
    { id: 'PORTAL', name: 'Portal' },
    { id: 'SEARCH_AND_CREATE', name: 'Search and create' },
    { id: 'WORKFLOW', name: 'Workflow' },
    { id: 'LANGUAGE_MINING', name: 'Language mining' },
    { id: 'EXTERNAL', name: 'External' }
  ]} />
]

export const ListSizeFooter = () => {
  const { data } = useListContext()
  return <div>Total: {data?.length} ({SINGLE_PAGE_LIMIT} is the current max (no pagination yet))</div>
}

const ListActions = () => (
  <TopToolbar>
    <SelectColumnsButton />
    <FilterButton/>
  </TopToolbar>
)

const AssetHistory = () => {
  const { record } = useEditContext()
  return (
    (record.assetHistory && record.assetHistory.length > 0) ? <TableContainer component={Paper}>
      <Table sx={{ minWidth: 650, backgroundColor: '#f5f5f5' }} aria-label="simple table">
        <TableHead>
          <TableRow>
            <TableCell>ID</TableCell>
            <TableCell align="right">Source lang</TableCell>
            <TableCell align="right">Target lang</TableCell>
            <TableCell align="right">Source text</TableCell>
            <TableCell align="right">Target text</TableCell>
            <TableCell align="right">Translation level</TableCell>
            <TableCell align="right">Creator</TableCell>
            <TableCell align="right">Creation date</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {record.assetHistory.map((row: any) => (
            <TableRow
              key={row.id}
              sx={{ '&:last-child td, &:last-child th': { border: 0 }, backgroundColor: (record.id === row.id ? '#e6e6e6' : null) }}
            >
              <TableCell component="th" scope="row">{row.id}</TableCell>
              <TableCell align="right">{row.sourceLang}</TableCell>
              <TableCell align="right">{row.targetLang}</TableCell>
              <TableCell align="right">{row.sourceText}</TableCell>
              <TableCell align="right">{row.targetText}</TableCell>
              <TableCell align="right">{row.translationLevel}</TableCell>
              <TableCell align="right">{row.creator}</TableCell>
              <TableCell align="right">{showTimeInTimezone(row.creationDate)}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer> : <Typography sx={{ font: 'normal normal bold 20px/25px Inter' }}>There is no asset history</Typography>
  )
}

const EditToolbar = () => {
  return <Toolbar sx={{ justifyContent:'space-between' }}>
    <SaveButton/>
  </Toolbar>
}

const AssetEdit = () => {
  const record = useRecordContext()
  const resource = useResourceContext()
  const notify = useNotify()
  const redirect = useRedirect()
  const refresh = useRefresh()
  const [, setExpandedIds] = useStore<Identifier[]>(`assets.datagrid.expanded`, [])
  const onSuccess = (data: any) => {
    notify('ra.notification.updated', {
      type: 'info',
      messageArgs: { smart_count: 1 },
      undoable: true
    })
    redirect('list', resource, data.id, data)
    setExpandedIds([])
    refresh()
  }

  return (<>
    <Edit
      resource={resource}
      id={record.id}
      /* disable the app title change when shown */
      title=" "
      mutationOptions={{ onSuccess }}
    >
      <>
        {<SimpleForm mode="onChange" reValidateMode='onChange' toolbar={<EditToolbar />}>
          <Box display={{ xs: 'block', sm: 'flex', width: '100%' }}>
            <Box flex={1} mr={{ xs: 0, sm: '0.5em' }}>
              <SelectInput source="sourceLang" choices={getOptionsSelectFromArray(languages, DICT_LANG)} sx={{ width: '223px' }} disabled/>
            </Box>
            <Box flex={1} mr={{ xs: 0, sm: '0.5em' }}>
              <SelectInput source="targetLang" choices={getOptionsSelectFromArray(languages, DICT_LANG)} sx={{ width: '223px' }} disabled/>
            </Box>
            <Box flex={1} mr={{ xs: 0, sm: '0.5em' }}>
              <TaxonomyMultiSearchInput defaultOptions={convertTaxonomyKeysToOptions(record.taxonomyKey)} defaultValues={record.taxonomyKey} disabled/>
            </Box>
          </Box>
          <TextInput source="sourceText" multiline minRows={1} maxRows={3} validate={[required(), requiredNotBlank()]} sx={{ marginTop: '8px' }} fullWidth disabled/>
          <TextInput source="targetText" multiline minRows={1} maxRows={3} validate={[required(), requiredNotBlank()]} sx={{ marginTop: '8px' }} fullWidth/>
        </SimpleForm>}
        <AssetHistory />
      </>
    </Edit>
  </>
  )
}

const textOverflowStyle = { display: "-webkit-box", WebkitLineClamp: 2, WebkitBoxOrient: "vertical", overflow: "hidden", textOverflow: "ellipsis" }

const AssetsList = () => {
  return <List 
    actions={<ListActions />} 
    perPage={SINGLE_PAGE_LIMIT} 
    pagination={<ListSizeFooter />} 
    filters={assetsFilters} 
    filterDefaultValues={{ searchType: 'SOURCE_FULL' }}
    sort={{ field: 'SOURCE_TEXT', order: 'ASC' }}
  >
    <DatagridConfigurable
      expand={<AssetEdit />}
      bulkActionButtons={false}
      sx={{
        '& .column-sourceText': { width: '30%' },
        '& .column-targetText': { width: '30%' }
      }}
    >
      <TextField source='id' sortable={false} />
      <TextField source='sourceLang' label={'Source lang'} sortable={false} />
      <TextField source='targetLang' label={'Target lang'} sortable={false} />
      <TextField source='sourceText' label={'Source text'} sortable={false} sx={textOverflowStyle} />
      <TextField source='targetText' label={'Target text'} sortable={false} sx={textOverflowStyle} />
      <FunctionField source="taxonomyKey" label={'Taxonomy keys'} sortable={false} 
        render={(record: { taxonomyKey: string[] } | undefined) => record && record.taxonomyKey && record.taxonomyKey.map((v: string) => stripCategoryAndIQVIAPrefix(v)).join("; ") }/>
      <TextField source='translationLevel' label={'Translation level'} sortable={false} />
      <TextField source='translationEngine' label={'MT Engine'} sortable={false} />
      <CustomLinkField source="creator" label={'Creator'} sortable={false} basePath='users' show targetFieldId='creator' />
      <TextField source='creationSource' label={'Creation source'} sortable={false} />
      <CustomDateField source='creationDate' label={'Creation date'} sortable={false} />
    </DatagridConfigurable>
  </List>
}

export default React.memo(AssetsList)