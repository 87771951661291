import React, { useState, useEffect } from 'react'
import { Button, IconButton, Box, Modal, Typography } from "@mui/material"
import { addTagToEntity, removeTagFromEntity, TagDto, TagEntityType, getTagsForEntity } from "api/tags"
import { useRecordContext } from "react-admin"
import { RoundedPanel } from "common/RoundedPanel/RoundedPanel"
import Tag from "./Tag"
import { NormText } from 'job/jobShow/Common'
import { EditIcon } from 'common/Icons'
import TagForEntitySelector from './TagForEntitySelector'

interface AddTagsToEntityModalProps {
  open: boolean
  onClose: () => void
  selectedTags: TagDto[]
  entityType: TagEntityType
}

interface EntityTagListViewWithEditProps {
  entityId: string
  entityType: TagEntityType
  visibleCount?: number
}

export const EntityTagListViewWithEdit = ({ entityId, entityType, visibleCount = 3 }: EntityTagListViewWithEditProps) => {
  const [tagsModalOpen, setTagsModalOpen] = useState(false)
  const [tags, setTags] = useState<TagDto[]>([])

  const loadTags = async () => {
    try {
      const entityTags = await getTagsForEntity(entityId)
      setTags(entityTags)
    } catch (error) {
      console.error('Failed to load tags:', error)
    }
  }

  useEffect(() => {
    if (entityId) loadTags()
  }, [entityId])

  return (
    <>
      <AddTagsToEntityModal
        open={tagsModalOpen}
        onClose={() => {
          setTagsModalOpen(false)
          loadTags()
        }}
        selectedTags={tags}
        entityType={entityType}
      />
      <Box display="flex" alignItems="center" flexWrap="wrap" gap={1}>
        <NormText><b>Tags:</b></NormText>
        {tags.slice(0, visibleCount).map((tag) => (
          <Tag key={tag.id} color={tag.color} name={tag.name} />
        ))}
        {tags.length > visibleCount && (
          <Tag color="#E0E0E0" name={`+${tags.length - visibleCount}`} />
        )}
        <IconButton
          size="small"
          onClick={() => setTagsModalOpen(true)} >
          <EditIcon />
        </IconButton>
      </Box>
    </>
  )
}

export const AddTagsToEntityModal = ({ open, onClose, selectedTags, entityType }: AddTagsToEntityModalProps) => {
  const [selected, setSelected] = useState<TagDto[]>([])
  const record = useRecordContext()

  useEffect(() => {
    setSelected(selectedTags)
  }, [selectedTags])

  const handleChange = (newValue: TagDto[]) => {
    const currentTagIds = new Set(selected.map(t => t.id))
    const newTagIds = new Set(newValue.map(t => t.id))
    
    // Handle removed tags
    for (const tag of selected) {
      if (!newTagIds.has(tag.id)) {
        removeTagFromEntity(record.id.toString(), tag.id.toString())
      }
    }

    // Handle added tags
    for (const tag of newValue) {
      if (!currentTagIds.has(tag.id)) {
        addTagToEntity(record.id.toString(), tag.id.toString())
      }
    }

    setSelected(newValue)
  }

  return (
    <Modal
      open={open}
      onClose={onClose}
      disableAutoFocus={true}
    >
      <RoundedPanel modal width="700px">
        <Typography variant="h6" sx={{ mb: 2 }}>
          Manage Tags
        </Typography>
        
        <TagForEntitySelector
          entityType={entityType}
          value={selected}
          onChange={handleChange}
        />

        <Box sx={{ mt: 2, display: 'flex', justifyContent: 'flex-end' }}>
          <Button onClick={onClose}>
            Close
          </Button>
        </Box>
      </RoundedPanel>
    </Modal>
  )
}