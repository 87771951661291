import React, { useEffect, useState } from "react"
import { useGetList, useRefresh, useTranslate } from "react-admin"
import Box from "@mui/material/Box"

import "styles.scss"
import ActionButton from "components/ActionButton"
import {
  CompanyPortalProperties,
  getCompanyPortalProperties,
  getCopanyPortalLogoPath,
  Tablet,
  TabletType
} from "api/company"
import RoundedPanel, { CrossCheckbox, Label } from "common/RoundedPanel"
import { EditPortalProperties } from "./companyModals/EditPortalProperties"
import { Chipify } from "common/Chipify"
import { Chip, Stack } from "@mui/material"
import UserService from "UserService"
import { UncacheableImg } from "common/UncacheableImg"
import { EditTablets } from "./companyModals/EditTablets"

const PortalProperties = ({ companyId }: { companyId: string }) => {
  const translate = useTranslate()
  const [open, setOpen] = useState<boolean>(false)
  const [openTabletsEdit, setOpenTabletsEdit] = useState<boolean>(false)
  const [portalProperties, setPortalProperties] = useState<CompanyPortalProperties>()
  const [portalLogoPath, setPortalLogoPath] = useState<string>()
  const { data: tablets, isLoading } = useGetList<Tablet>("tablets", { filter: { companyId: companyId } })
  const refresh = useRefresh()

  const fetchPortalProperties = () => {
    getCompanyPortalProperties(companyId.toString()).then((properties) => {
      setPortalProperties(properties)
      properties &&
        getCopanyPortalLogoPath(companyId.toString()).then((logoPath) => {
          setPortalLogoPath(logoPath)
        })
    })
  }
  useEffect(fetchPortalProperties, [])

  if (isLoading || !tablets) return <></>

  return (
    <>
      {portalProperties != undefined && (
        <>
          {UserService.hasRoles(["ROLE_PLATFORM_MANAGER"]) && (
            <>
              {tablets.length > 0 && (
                <EditTablets
                  companyId={companyId}
                  open={openTabletsEdit}
                  onClose={() => setOpenTabletsEdit(false)}
                  onSave={() => {
                    setOpenTabletsEdit(false)
                    refresh()
                  }}
                  initialTablets={tablets}
                />
              )}
              <EditPortalProperties
                companyId={companyId}
                portalProperties={portalProperties}
                open={open}
                onClose={() => setOpen(false)}
                onSave={fetchPortalProperties}
                portalLogoPath={portalLogoPath || null}
                tablets={tablets}
                onSetupCommonTablets={refresh}
              />
              <Box className="edit-tab">
                <Box className="on-left">
                  <ActionButton
                    onClick={() => {
                      setOpen(true)
                    }}
                  >
                    Edit
                  </ActionButton>
                  {tablets.length > 0 && (
                    <ActionButton
                      onClick={() => {
                        setOpenTabletsEdit(true)
                      }}
                    >
                      Edit tablets status
                    </ActionButton>
                  )}
                </Box>
              </Box>
            </>
          )}
          {(portalProperties && (
            <Box className="panel">
              <Box width="600px">
                <RoundedPanel>
                  <>
                    {portalLogoPath && (
                      <>
                        <UncacheableImg className="logo" src={portalLogoPath} alt="Application logo" />
                        <hr />
                      </>
                    )}
                    <Label noSelect>Genre: </Label>
                    <Chipify source={[portalProperties.genre]} translation="dict.genre." variant="squere-chip" />
                    <br />
                    <Label>Skillset node: </Label>
                    {stripCategoryPrefix(portalProperties.taxonomy)}
                    <br />
                    <hr />
                    <Label>Active languages: </Label>
                    <Chipify
                      source={portalProperties.allowedLanguageList.sort()}
                      translation="dict.lang."
                      variant="squere-chip"
                    />
                    <br />
                    <hr />
                    <Label>Translate&Create default language combination: </Label>
                    <br />
                    {translate(`dict.lang.${portalProperties.defaultLanguageCombinationTranslateAndCreateFrom}`)} {"->"}{" "}
                    {translate(`dict.lang.${portalProperties.defaultLanguageCombinationTranslateAndCreateTo}`)}
                    <hr />
                    <Label>Workflow default language combination: </Label>
                    <br />
                    {translate(`dict.lang.${portalProperties.defaultLanguageCombinationFileFrom}`)} {"->"}{" "}
                    {translate(`dict.lang.${portalProperties.defaultLanguageCombinationFileTo}`)}
                    <hr />
                    <CrossCheckbox checked={portalProperties.showDiscoverMe} id="showDiscoverMe" />
                    Discover More
                  </>
                </RoundedPanel>
              </Box>
              <Box width="600px">
                <RoundedPanel>
                  <TabletGroup tablets={tablets} type="KM" label="ASK/CREATE tablets:" />
                  <hr />
                  <TabletGroup tablets={tablets} type="OUTSOURCING" label="Outsourcing tablets:" />
                  <hr />
                  <TabletGroup tablets={tablets} type="INSOURCING" label="Insourcing tablets:" />
                </RoundedPanel>
              </Box>
            </Box>
          )) ||
            "No Portal Properties configured yet"}
        </>
      )}
    </>
  )
}

export default PortalProperties

export const stripCategoryPrefix = (text: string) => text.replace("Category:", "")

export const stripCategoryAndIQVIAPrefix = (text: string) => text.replace("Category:", "").replace("IQVIA:", "")

const TabletGroup = ({ tablets, type, label }: { tablets: Tablet[]; type: TabletType; label: string }) => {
  return (
    <Stack>
      <Label>{label}</Label>
      {tablets &&
        tablets
          .filter((t) => t.type === type)
          .map((t) => (
            <Stack alignItems={"center"} direction={"row"}>
              <Label noSelect>{t.title}: </Label>
              <Chip key={t.id} className={"squere-chip"} label={t.status} />
            </Stack>
          ))}
    </Stack>
  )
}
