import React, { useState } from 'react'
import { 
  Dialog, 
  DialogTitle, 
  DialogContent, 
  DialogActions, 
  Button, 
  Typography 
} from '@mui/material'
import { deleteTag, TagDetailsDto } from 'api/tags'
import { useRecordContext, useNotify, useRedirect } from 'react-admin'
import DeleteIcon from '@mui/icons-material/Delete'
const TagDeleteButton: React.FC = () => {
  const record = useRecordContext<TagDetailsDto>()
  const [open, setOpen] = useState(false)
  const notify = useNotify()
  const redirect = useRedirect()

  const handleConfirm = async () => {
    try {
      await deleteTag(record.id)
      notify('Tag deleted successfully', { type: 'success' })
      redirect('list', 'tags')
    } catch (err) {
      notify('Error deleting tag', { type: 'error' })
      setOpen(false)
    }
  }

  return (
    <>
      <Button onClick={() => setOpen(true)} color="error" variant="contained" startIcon={<DeleteIcon />}>Delete</Button>
      <DeleteTagDialog
        open={open}
        tag={record}
        onConfirm={handleConfirm}
        onCancel={() => setOpen(false)}
      />
    </>
  )
}

interface DeleteTagDialogProps {
  open: boolean
  tag: TagDetailsDto
  onConfirm: () => void
  onCancel: () => void
}

const DeleteTagDialog: React.FC<DeleteTagDialogProps> = ({ 
  open, 
  tag, 
  onConfirm, 
  onCancel 
}) => {
  return (
    <Dialog open={open} onClose={onCancel}>
      <DialogTitle>Delete Tag</DialogTitle>
      <DialogContent>
        <Typography>
          Are you sure you want to delete the tag {tag.name}?
          {tag.relatedEntitiesCount > 0 && (
            <Typography color="error" sx={{ mt: 1 }}>
              Warning: This tag is used by {tag.relatedEntitiesCount} entities.
            </Typography>
          )}
        </Typography>
      </DialogContent>
      <DialogActions>
        <Button onClick={onCancel}>No, Cancel</Button>
        <Button onClick={onConfirm} color="error" variant="contained">
          Yes, Delete
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default TagDeleteButton 