import React from 'react'
import { Create, Edit, SaveButton, SimpleForm, TextInput, Toolbar, useRecordContext } from 'react-admin'
import TagDeleteButton from './TagDeleteButton'
import TagEntityTypeSelector from './TagEntityTypeSelector'

const TagTitle = () => {
  const record = useRecordContext()
  return <span>Tag {record?.name}</span>
}

export const TagEdit = (props: any) => {
  return (
    <Edit {...props} title={<TagTitle />}>
      <SimpleForm toolbar={<Toolbar><SaveButton sx={{ mr: 2 }} /><TagDeleteButton /></Toolbar>}>
        <TextInput source="name" />
        <TextInput 
          source="color"
          type="color"
          sx={{
            '& .MuiInputBase-input': {
              width: '250px',
              height: '40px',
              padding: '4px',
              cursor: 'pointer'
            }
          }}
        />
        <TagEntityTypeSelector 
          source="entityType"
          disabled
        />
        <TextInput 
          source="relatedEntitiesCount"
          disabled
        />
      </SimpleForm>
    </Edit>
  )
}

export const TagCreate = (props: any) => (
  <Create {...props} redirect="list">
    <SimpleForm>
      <TagEntityTypeSelector source="entityType" />
      <TextInput source="name" />
      <TextInput 
        source="color"
        type="color"
        defaultValue="#03e92b"
        sx={{
          '& .MuiInputBase-input': {
            width: '250px',
            height: '40px',
            padding: '4px',
            cursor: 'pointer'
          }
        }}
      />
    </SimpleForm>
  </Create>
)