import React, { ReactNode } from 'react'
import { Container } from "../components/Status"
import { useRecordContext } from 'react-admin'

interface TagProps {
  name: string
  color: string
  endIcon?: ReactNode
}

const Tag = ({ name, color, endIcon }: TagProps) => {
  return (
    <Container backgroundColor={color} color="#043958">
      {name}
      {endIcon}
    </Container>
  )
}

export default Tag

export const TagField = () => {
  const record = useRecordContext()
  if (!record) return null
  return <Tag name={record.name} color={record.color} />
}
