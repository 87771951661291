import React from 'react'
import { Box } from '@mui/material'
import MDEditor from '@uiw/react-md-editor'

interface EntityNoteProps {
    entityId: string
    markdown: string
    hideLabel?: boolean
}

export const EntityNote: React.FC<EntityNoteProps> = ({ markdown }) => {
  if (!markdown) {
    return null
  }

  return (
    <Box mt={2}>
      <MDEditor.Markdown source={markdown} />
    </Box>
  )
}