import React, { useState } from "react"
import { Box, Grid, Modal } from "@mui/material"
import { Tablet, TabletStatus, TabletType, updateTablets } from "api/company"
import { SumbitButtons } from "common/SubmitButtons"

import "styles.scss"
import { CustomSelectInput } from "common/OutlinedSelectInput"
import { Label } from "common/RoundedPanel"

interface EditTabletsProps {
  companyId: string
  initialTablets: Tablet[]
  open: boolean
  onClose: () => void
  onSave?: () => void
}

export const EditTablets = ({ companyId, open, onClose, onSave, initialTablets }: EditTabletsProps) => {
  const [tablets, setTablets] = useState<Tablet[]>(initialTablets)

  const updateTablet = (tabletId: string, status: TabletStatus) => {
    setTablets((prevItems) => prevItems.map((item) => (item.id === tabletId ? { ...item, status: status } : item)))
  }

  return (
    <Modal open={open} onClose={onClose} disableAutoFocus={true} sx={{ overflow: "scroll" }}>
      <Box className="rounded-panel edit-modal">
        <h1>Edit tablets</h1>
        <Box height={13} />
        <TabletsGroup tablets={tablets} updateTablet={updateTablet} type="KM" label="ASK/CREATE tablets:" />
        <hr />
        <TabletsGroup tablets={tablets} updateTablet={updateTablet} type="OUTSOURCING" label="Outsourcing tablets:" />
        <hr />
        <TabletsGroup tablets={tablets} updateTablet={updateTablet} type="INSOURCING" label="Insourcing tablets:" />
        <SumbitButtons
          onSave={() => updateTablets(companyId, tablets).then(onSave)}
          onClose={() => {
            setTablets(initialTablets)
            onClose()
          }}
        />
      </Box>
    </Modal>
  )
}

const TabletsGroup = ({
  tablets,
  updateTablet,
  type,
  label
}: {
  tablets: Tablet[]
  updateTablet: (tabletId: string, status: TabletStatus) => void
  type: TabletType
  label: string
}) => {
  const getChoices = (tablet: Tablet) => {
    const choices = [
      { id: "ACTIVE", name: "ACTIVE" },
      { id: "DISABLED", name: "DISABLED" }
    ]
    if (tablet.type === "OUTSOURCING" || tablet.name == "enhance" || tablet.name == "trustmine")
      return [...choices, { id: "INACTIVE", name: "INACTIVE" }]
    return choices
  }

  return (
    <>
      <Label>{label}</Label>
      <Grid container>
        {tablets
          .filter((t) => t.type === type)
          .map((t) => (
            <Grid sx={{ p: 1 }}>
              <CustomSelectInput
                label={t.title}
                choices={getChoices(t)}
                alwaysOn
                initValue={t.status}
                clearable={false}
                onChange={(v) => updateTablet(t.id, v as TabletStatus)}
              />
            </Grid>
          ))}
      </Grid>
    </>
  )
}
