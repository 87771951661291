import { Modal, Box, InputAdornment, IconButton } from "@mui/material"
import { WhiteTextField } from "common/TextField"
import { RaRecord, useNotify, useDataProvider, useRefresh } from "ra-core"
import React, { useState } from "react"
import { ModalProps } from "./common"
import { Chipify } from "common/Chipify"
import RoundedPanel, { Alert } from "common/RoundedPanel"
import { SumbitButtons } from "common/SubmitButtons"
import { PricingType, Tablet } from "api/company"

interface EditPricingRuleModalProps {
  type: PricingType
  tablet?: Tablet
}

export const EditPricingRuleModal = ({ open, onClose, record, type, tablet }: ModalProps<RaRecord> & EditPricingRuleModalProps) => {
  const { id, price, companyId, stages } = record

  const [pricePerWord, setPricePerWord] = useState<number>(price)
  const [deleteState, setDeleteState] = useState<"NONE" | "WARN" | "TABLET_EXISTS">(tablet && "TABLET_EXISTS" || "NONE")

  const notify = useNotify()
  const refresh = useRefresh()
  const dataProvider = useDataProvider()

  const applyChanges = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault()
    dataProvider.create(`pricingrules`,
      { data: { price: pricePerWord, stages: stages, companyId: companyId, type: type, pricingIdToDelete: id } })
      .then(() => {
        notify('Price rule was successfully updated.', { type: 'success' })
        refresh()
        onClose()
      })
      .catch((error: any) => {
        refresh()
        notify(error?.message ?? 'Something went wrong.', { type: 'error' })
      }
      )
  }

  const onDelete = () => {
    if (deleteState == "NONE") {
      setDeleteState("WARN")
    }
    if (deleteState == "WARN") {
      dataProvider
        .delete('pricingrules', { id: id, previousData: record })
        .then(() => {
          notify('Price rule was successfully deleted.', { type: 'success' })
          onClose()
        })
        .catch((error) => {
          notify(error?.message ?? 'Something went wrong.', { type: 'error' })
        })
        .finally(() => {
          refresh()
        })
    }
  }

  const deleteWarn = deleteState == "WARN" ?  <Box margin="20px 0px">
    <Alert
      variant="warn"
    >
      Are you sure you wanto to delete this item?
    </Alert>
  </Box> : <Box height={32} />

  return <Modal
    open={open}
    onClose={onClose}
    disableAutoFocus={true}
  >
    <RoundedPanel modal width="400px">
      <form
        onSubmit={applyChanges}
      >
        <h1>Stage: <Chipify source={stages} translation="stage.type." variant="squere-chip" /></h1>
        <WhiteTextField
          id="pricePerWord"
          label="Price per word"
          fullWidth
          onChange={(e) => { setPricePerWord(parseFloat(e.target.value)) }}
          defaultValue={price}
          type='number'
          InputProps={{
            endAdornment: <InputAdornment position="start">€</InputAdornment>
          }}
          inputProps={{
            step: 0.0001,
            min: 0,
            max: 99
          }}
        />
        {deleteWarn}
        <SumbitButtons
          onClose={onClose}
          onDelete={stages.length > 0 ? onDelete : undefined}
          onDeleteLabel="Delete pricing"
          onDeleteDisabled={deleteState == 'TABLET_EXISTS'}
          onDeleteDisabledMessage={deleteState == 'TABLET_EXISTS' ? "Please delete Tablet first" : undefined}
        />
      </form>
    </RoundedPanel>
  </Modal>
}
