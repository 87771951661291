import axios from "axios"

export enum TagEntityType {
  PROJECT = 'PROJECT',
  COMPANY = 'COMPANY',
  USER = 'USER',
  GATED_COMMUNITY = 'GATED_COMMUNITY'
}

export interface TagDto {
  id: string,
  entityType: TagEntityType,
  name: string,
  color: string,
}

export interface TagDetailsDto extends TagDto {
  relatedEntitiesCount: number
}

export interface CreateTagRequest {
  entityType: TagEntityType,
  name: string,
  color: string
}

/**
 * Add a tag to an entity
 * @param entityId The ID of the entity to tag
 * @param tagId The ID of the tag to add
 */
export async function addTagToEntity(entityId: string, tagId: string) {
  await axios.put(`/api/admin/entities/${entityId}/tags/${tagId}`)
}

/**
 * Get all tags for a given entity
 * @param entityId The ID of the entity
 * @returns List of tags associated with the entity
 */
export async function getTagsForEntity(entityId: string) {
  const response = await axios.get<TagDto[]>(`/api/admin/entities/${entityId}/tags`)
  return response.data
}

/**
 * Remove a tag from an entity
 * @param entityId The ID of the entity
 * @param tagId The ID of the tag to remove
 */
export async function removeTagFromEntity(entityId: string, tagId: string) {
  await axios.delete(`/api/admin/entities/${entityId}/tags/${tagId}`)
}

/**
 * Get all tags, optionally filtered by entity type
 * @param entityType Optional entity type to filter by
 * @returns List of tags
 */
export async function getAllTags(entityType?: string, limit = 5) {
  const url = entityType 
    ? `/api/admin/tags?entityType=${entityType}&_limit=${limit}`
    : `/api/admin/tags?_limit=${limit}`
  const response = await axios.get<TagDto[]>(url)
  return response.data
}

/**
 * Delete a tag
 * @param tagId The ID of the tag to delete
 */
export async function deleteTag(tagId: string) {
  await axios.delete(`/api/admin/tags/${tagId}`)
}
