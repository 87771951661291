import React from 'react'
import { TagEntityType } from 'api/tags'
import { SelectInput, SelectInputProps } from 'react-admin'

const formatEnumForDisplay = (value: string) => {
  return value.replace(/_/g, ' ')
}

const getTagEntityTypeChoices = () => {
  return Object.values(TagEntityType).map(type => ({ 
    id: type, 
    name: formatEnumForDisplay(type as string)
  }))
}

export const getTagEntityTypeOptions = () => {
  return Object.values(TagEntityType).map(type => ({ 
    id: type, 
    name: formatEnumForDisplay(type)
  }))
}

export const TagEntityTypeSelector = (props: Omit<SelectInputProps, 'choices'>) => {
  return (
    <SelectInput
      {...props}
      choices={getTagEntityTypeChoices()}
    />
  )
}

export default TagEntityTypeSelector 