import React from 'react'
import { useRecordContext } from 'ra-core'
import Box from '@mui/material/Box'
import { GatedCommunityRecord } from 'api/gatedCommunity'

import 'styles.scss'
import ActionButton from 'components/ActionButton'
import UserService from 'UserService'
import RoundedPanel, { CrossCheckbox, Label } from 'common/RoundedPanel'
import { Chipify } from 'common/Chipify'
import { AddMember } from 'gatedCommunity/gcModals/AddMember'
import { handleEsc } from 'common/closeOnEscape'
import { EditGC } from 'gatedCommunity/gcModals/EditGC'
import { Chip, Tooltip } from '@mui/material'
import { EntityTagListViewWithEdit } from 'tags/EntityTagListViewWithEdit'
import { TagEntityType } from 'api/tags'

const Profile = () => {
  const record = useRecordContext<GatedCommunityRecord>()

  const [open, setOpen] = React.useState<"ADD" | "EDIT" | undefined>(undefined)

  React.useEffect(handleEsc(() => { setOpen(undefined) }), [])

  if (!record) return null

  const addMemberButton = UserService.hasRoles(['ROLE_PLATFORM_MANAGER', 'ROLE_GATED_COMMUNITY_MANAGER']) &&
    <ActionButton
      onClick={() => { setOpen("ADD") }}
    >Add member</ActionButton>

  const editGC = UserService.hasRoles(['ROLE_PLATFORM_MANAGER']) &&
    <ActionButton
      onClick={() => { setOpen("EDIT") }}
    >Edit</ActionButton>

  const RecyclingLevel = () => {
    if (record.recyclableTranslationLevels.length == 0) return <Tooltip title="Defaults to Trust Mining"><Chip label='None' className="squere-chip" /></Tooltip>

    return <Chipify source="recyclableTranslationLevels" translation="dict.recyclableTranslationLevels." variant="squere-chip" />
  }

  return <>
    <Box className="edit-tab">
      <Box className='on-left'>
        {addMemberButton}
        {editGC}
      </Box>
    </Box >
    <AddMember
      record={record}
      open={open === "ADD"}
      onClose={() => { setOpen(undefined) }}
    />
    <EditGC
      record={record}
      open={open === "EDIT"}
      onClose={() => { setOpen(undefined) }}
    />

    <Box className="panel">
      <Box>
        <RoundedPanel width="600px" >
          <Label>Gated Community: </Label> {record.name}<br />
          <Box height={13} />
          <EntityTagListViewWithEdit entityId={record.id.toString()} entityType={TagEntityType.GATED_COMMUNITY} />
          <hr />
          <Label>Recycling level: </Label> <RecyclingLevel /><br />
          <hr />
          <CrossCheckbox checked={record.anonymizationEnabled} disableRipple />Anonymization enabled
        </RoundedPanel>
      </Box>
      <Box>
        <RoundedPanel width="600px" >
          <Label>Stats</Label>
          <hr />
          <Label noSelect>Members total: </Label>{record.membersCount}<br />
          <Label noSelect>Members active (last 30 days): </Label>{record.activeMembersCount}<br />
          <Box height={16} />
          <Label noSelect>Projects Total: </Label>{record.jobsCount}<br />
          <Label noSelect>Projects Requested (last 30 days): </Label>{record.latestJobsCount}<br />
        </RoundedPanel>
      </Box>
    </Box>
  </>
}

export default Profile