import axios from "axios"
import FileSaver from "file-saver"
import { FileType } from "./stages"

export async function downloadFile (path: string, params: object = {}, name?: string) {
  const response = await axios.get<any>(path,
    { responseType: 'blob', params: params })
  const contentType = response.headers['content-type']
  const [, fileName] = response.headers['content-disposition']?.split(';')[1]?.split('=') || []
  FileSaver.saveAs(new Blob([response.data], { type: contentType }), name||fileName)
}

export async function changeDeadline (id: string, deadline: string, type: 'jobs' | 'stages' | 'assignments') {
  await axios.post(`/api/admin/${type}/${id}/changeDeadline`, deadline)
}

export type Role = 'ROLE_ENHANCER' | 'ROLE_BUYER' | 'ROLE_LANGUAGE_MINER' | 'ROLE_POLISHER' | 'ROLE_TRUST_MINER' | 'ROLE_PLAIN' | 'ROLE_REDACTOR' | 'ROLE_BI' |
 'ROLE_EXFLUENCY_ADMIN' | 'ROLE_COMMUNITY_MANAGER' | 'ROLE_PLATFORM_MANAGER' | 'ROLE_SUPPORT' | 'ROLE_OCR_ENGINE' | 'ROLE_ANONYMIZATION_ENGINE' |'ROLE_MT_ENGINE' |
 'ROLE_GATED_COMMUNITY_MANAGER' | 'ROLE_FINANCE' | 'ROLE_KNOWLEDGE_MINER'

export interface SelectOption {
  id: string,
  name: string
}

export type Currency = 'EUR' | 'USD'| 'CHF' | 'JPY'

export function currencySymbol (currency?: Currency) {
  if (!currency) return null

  switch (currency) {
    case 'EUR': return '€'
    case 'USD': return '$'
    case 'CHF': return 'CHF'
    case 'JPY': return '¥'
    default: return "N/A"
  }
}

export type Genre = 'it' | 'technical' | 'sports' | 'legal' | 'life_sciences' | 'marketing' | 'other'

export type StageType = 'FILE_PREPARATION' | 'OCR' | 'FILE_PARSING' | 'ANONYMIZATION' | 'DOCUMENT_PARSING' | 'TABLE_PARSING' | 'REDACTION' | 'FINAL_REDACTION' | 'RECYCLING' | 'ENHANCEMENT' | 'TRUST_MINING' | 'CORRECTION'

export const isHumanExecuted = (stage: StageType) =>
  ["REDACTION", "FINAL_REDACTION", "ENHANCEMENT", "TRUST_MINING", "CORRECTION"].includes(stage)

enum StageTypeOrdered {
  FILE_PREPARATION,
  FILE_PARSING,
  TABLE_PARSING,
  ANONYMIZATION,
  REDACTION,
  RECYCLING,
  ENHANCEMENT,
  TRUST_MINING,
  FINAL_REDACTION,
  CORRECTION,
}

const stageTypeOrder = Object.values(StageTypeOrdered)

export const sortStageTypes = (stages: StageType[]) => {
  return stages.sort((s1, s2) => stageTypeOrder.indexOf(s1) - stageTypeOrder.indexOf(s2))
}

export type StageStatus = 'IN_PROGRESS' | 'DONE' | 'WAITING' | 'ERROR' | 'PENDING_ACCEPTANCE' | 'STARTED' | 'NO_EXECUTORS' | 'CANCELLED' | 'SUPERSEDED'

export type StageParameter = 'Comment' | 'ExecutorSelection' | 'Rated'

export const ASSIGNMENT_STATUS_LIST = ['PENDING', 'ACCEPTED', 'REJECTED', 'MISSED', 'DELIVERED', 'CANCELLED', 'SUPERSEDED', 'FINISHED', 'INTERRUPTED']
export type AssignmentStatus = typeof ASSIGNMENT_STATUS_LIST[number]

export type JobStatus = 'IN_PROGRESS' | 'BLOCKED' | 'FINISHED' | 'ERROR' | 'DELETED' | 'CANCELLED' | 'QUOTED'

export interface Skill {
  langFrom: string,
  langTo: string,
  currency: Currency,
  price: number,
  taxonomy: string[],
}

export interface InvoicesRecord {
  id: string,
  type: InvoiceType,
  sum: Money,
  accepted: boolean,
  pdfReady: boolean,
  pdfFileId?: string,
  creationDate: string,
  startDate: string,
  endDate: string
}

export type InvoiceType = 'EARNINGS' | 'EXPENSES'

export interface Money {
  currency: Currency,
  value: number
}

export interface PricingRule {
  id: string,
  stages: StageType[],
  companyId?: string,
  price: number
}

export interface Comment {
  id: string,
  author: string,
  text: string,
  creationTime: string,
  replies: Comment[]
}

export type ProfileStatus = 'ORGANIZATION_ADMIN' | 'ORGANIZATION_MEMBER' | 'GATED_COMMUNITY_ADMIN' | 'GATED_COMMUNITY_MEMBER'

export interface AllowedRole {
  role: Role,
  priority: number
}

export interface LanguagePair {
  languageFrom: string,
  languageTo: string
}

export interface StageJobSection {
  id: string,
  name: string,
  availableFileTypes: FileType[],
  readyFileTypes: FileType[],
}