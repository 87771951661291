import React, { useState } from 'react'
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button
} from '@mui/material'
import MDEditor from '@uiw/react-md-editor'
import { saveNoteForEntity } from '../api/notes'
import { useNotify } from 'ra-core';

interface NoteEditModalProps {
    open: boolean;
    onClose: () => void;
    entityId: string;
    initialMarkdown: string;
    onSave: (markdown: string) => void;
}

const NoteEditModal: React.FC<NoteEditModalProps> = ({
  open,
  onClose,
  entityId,
  initialMarkdown,
  onSave
}) => {
  const [markdown, setMarkdown] = useState(initialMarkdown)
  const notify = useNotify()

  const handleSave = async () => {
    try {
      await saveNoteForEntity(entityId, markdown)
      onSave(markdown)
    } catch (error: any) {
      if (error.response?.status === 400) {
        notify(error.response.data.message || 'Bad request', { type: 'error' })
      } else {
        notify('An error occurred', { type: 'error' })
      }
    }
  }

  const handleClose = () => {
    setMarkdown(initialMarkdown)
    onClose()
  }

  return (
    <>
      <Dialog 
        open={open} 
        onClose={handleClose}
        maxWidth="md"
        fullWidth
      >
        <DialogTitle>Edit Note</DialogTitle>
        <DialogContent>
          <div data-color-mode="light" style={{ marginTop: '16px' }}>
            <MDEditor
              value={markdown}
              onChange={(val) => setMarkdown(val || '')}
              height={400}
            />
          </div>
        </DialogContent>
        <DialogActions>
          <Button variant='contained' onClick={handleSave}>
            Submit
          </Button>
          <Button onClick={handleClose}>Close</Button>          
        </DialogActions>
      </Dialog>
    </>
  )
}

export default NoteEditModal