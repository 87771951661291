import React, { useState, useEffect } from 'react'
import { Box, IconButton } from '@mui/material'
import RoundedPanel, { Label } from 'common/RoundedPanel'
import { EditIcon } from 'common/Icons'
import { EntityNote } from './EntityNote'
import NoteEditModal from './NoteEditModal'
import { getNoteForEntity } from '../api/notes'

const NotesBlock = ({ entityId, label = "Notes" }: { entityId: string, label?: string }) => {
  const [isModalOpen, setIsModalOpen] = useState(false)
  const [markdown, setMarkdown] = useState('')
  const [hasAccess, setHasAccess] = useState<boolean | null>(null)
    
  const fetchNote = async () => {
    try {
      const note = await getNoteForEntity(entityId)
      if (note === undefined) {
        setHasAccess(false) // Forbidden case
        setMarkdown('')
      } else {
        setHasAccess(true) // Both no content (null) and existing content cases
        setMarkdown(note?.markdown || '')
      }
    } catch (error) {
      setHasAccess(false)
      setMarkdown('')
    }
  }

  useEffect(() => {
    fetchNote()
  }, [entityId])
    
  const handleSave = (newMarkdown: string) => {
    setMarkdown(newMarkdown)
    setIsModalOpen(false)
  }
  
  return hasAccess ? (
    <RoundedPanel>
      <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
        <Label>{label}</Label>
        <IconButton 
          size="small" 
          onClick={() => setIsModalOpen(true)}
        >
          <EditIcon />
        </IconButton>
      </Box>
      <EntityNote 
        entityId={entityId}
        markdown={markdown}
        hideLabel 
      />
      <NoteEditModal
        open={isModalOpen}
        onClose={() => {
          setIsModalOpen(false)
          fetchNote()
        }}
        entityId={entityId}
        initialMarkdown={markdown}
        onSave={handleSave}
      />
    </RoundedPanel>
) : null
}

export default NotesBlock