import React from 'react'
import { useListContext } from 'react-admin'
import { FormControl } from '@mui/material'
import { TagDto } from 'api/tags'
import TagForEntitySelector from './TagForEntitySelector'

interface TagSearchFilterProps {
  source: string
  entityType?: string
}

const EntityTagSearchFilter = ({ source, entityType }: TagSearchFilterProps) => {
  const { filterValues, setFilters, displayedFilters } = useListContext()

  const handleChange = (newValue: TagDto[]) => {
    setFilters({ ...filterValues, [source]: newValue }, displayedFilters)
  }

  return (
    <FormControl style={{ minWidth: '15rem' }}>
      <TagForEntitySelector
        entityType={entityType}
        value={filterValues[source] || []}
        onChange={handleChange}
      />
    </FormControl>
  )
}

export default EntityTagSearchFilter
