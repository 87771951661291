import { fetchUtils, DataProvider } from 'ra-core'

const resourceToEndpointMapping = (resource: string): string => {
  if (resource === 'assets' || resource === 'subassets') {
    return "asset-manager"
  } else if (resource == 'multilang-jobs' || resource == 'tablets') {
    return "portal/api"
  }
  return "/api/admin"
}
  
export default (delegateDataProvider: (apiUrl: string, httpClient?: any) => DataProvider, httpClient = fetchUtils.fetchJson): DataProvider => ({
  getList: (resource, params) => {
    const endpoint = resourceToEndpointMapping(resource)
    const delegate = delegateDataProvider(endpoint, httpClient)
    return delegate.getList(resource, params)
  },
  getOne: (resource, params) => {
    const endpoint = resourceToEndpointMapping(resource)
    const delegate = delegateDataProvider(endpoint, httpClient)
    return delegate.getOne(resource, params)
  },
  getMany: (resource, params) => {
    const endpoint = resourceToEndpointMapping(resource)
    const delegate = delegateDataProvider(endpoint, httpClient)
    return delegate.getMany(resource, params)
  },
  getManyReference: (resource, params) => {
    const endpoint = resourceToEndpointMapping(resource)
    const delegate = delegateDataProvider(endpoint, httpClient)
    return delegate.getManyReference(resource, params)
  },
  update: (resource, params) => {
    const endpoint = resourceToEndpointMapping(resource)
    const delegate = delegateDataProvider(endpoint, httpClient)
    return delegate.update(resource, params)
  },
  updateMany: (resource, params) => {
    const endpoint = resourceToEndpointMapping(resource)
    const delegate = delegateDataProvider(endpoint, httpClient)
    return delegate.updateMany(resource, params)
  },
  create: (resource, params) => {
    const endpoint = resourceToEndpointMapping(resource)
    const delegate = delegateDataProvider(endpoint, httpClient)
    return delegate.create(resource, params)
  },
  delete: (resource, params) => {
    const endpoint = resourceToEndpointMapping(resource)
    const delegate = delegateDataProvider(endpoint, httpClient)
    return delegate.delete(resource, params)
  },
  deleteMany: (resource, params) => {
    const endpoint = resourceToEndpointMapping(resource)
    const delegate = delegateDataProvider(endpoint, httpClient)
    return delegate.deleteMany(resource, params)
  }
})