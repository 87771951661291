import axios from "axios"

export interface NoteRequest {
  markdown: string
}

export interface NoteResponse {
  entityId: string
  markdown: string
}

export async function getNoteForEntity (entityId: string): Promise<NoteResponse | null | undefined> {
  try {
    const response = await axios.get<NoteResponse>(`/api/admin/entities/${entityId}/note`)
    if (response.status === 204) {
      return null // No content case - should show empty editor
    }
    return response.data
  } catch (error) {
    if (axios.isAxiosError(error) && error.response?.status === 403) {    
      return undefined // Forbidden case - should hide the component      
    }
    throw error
  }
}

export async function saveNoteForEntity (entityId: string, markdown: string) {
  await axios.put(`/api/admin/entities/${entityId}/note`, { markdown } as NoteRequest)
}
